import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";
import firebase from "./firebase";


//Chat App
import { AuthProvider } from './AuthContext';

// import themeFile from './util/theme';
import jwtDecode from 'jwt-decode';
// Redux
import { Provider } from 'react-redux';
import store from './redux/store';
import { SET_AUTHENTICATED } from './redux/types';
import { logoutUser, getUserData } from './redux/actions/userActions';
// Components
import Navbar from './components/layout/Navbar';
import AuthRoute from './util/AuthRoute';
import themeObject from './util/theme';
import Firebasepush from './components/Firebasepush';
// Pages
import home from './pages/home';
import login from './pages/login';
import signup from './pages/signup';
import user from './pages/user';
import axios from 'axios';


//Chat
import Chats from './components/Chats';
import chatLogin from './components/ChatLogin';
import { MinimizeRounded } from '@material-ui/icons';

const theme = createMuiTheme(themeObject);

axios.defaults.baseURL = "https://us-central1-stfu-9a779.cloudfunctions.net/api";




const token = localStorage.FBIdToken;
if(token){
  const decodedToken = jwtDecode(token);
  if(decodedToken.exp * 1000 < Date.now()){
    store.dispatch(logoutUser())
    window.location.href = '/login';
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common['Authorization'] = token;
    store.dispatch(getUserData());

  }
}
  
  
  


  

  class App extends Component {
    render() {
      return (
        
          < Firebasepush />,

         
        

      <ThemeProvider theme={theme}>
        

      
        <Provider store={store}>
        <Router>
        
          <Navbar />
          <div className="container">
          <Switch>
          <Route path='/pegrcoin' component={() => { 
     window.location.href = 'http://www.pegrcoin.com/'; 
     return null;
}}/>
            <Route exact path="/" component={home}/>
            <AuthRoute exact path="/login" component={login} />
            <AuthRoute exact path="/signup" component={signup} />
            <Route exact path="/users/:handle" component={user} />
            <Route exact path="/users/:handle/scream/:screamId" component={user} />
          </Switch>
          <AuthProvider>
          <Switch>
             <Route path="/Chats" component={Chats} />
            <Route path="/ChatLogin" component={chatLogin} /> 
          </Switch>
        </AuthProvider>
          </div>
        </Router>
        </Provider>
      
      </ThemeProvider>
    )
  }
}


export default App;

