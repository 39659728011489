
const themePalette = {
  
 
    
    //the object to be spread
    spreadThis: {
        palette: {
          primary: {
            light: '#33eaff',
            main:  '#00e5ff',
            dark:  '#00a0b2',
            contrastText: '#fff'
            
          },
          secondary: {
            light: '#af52bf',
            main:  '#9c27b0',
            dark:  '#6d1b7b',
            contrastText: '#fff'
          },
          third: {
              light:'#01FFD1',
              main: '#01FFD3',
              dark: '#00d9b3',
              contrastText: '#fff'
          }
        },
      typography: {
        useNextVariants: true
      },
      form: {
        textAlign: 'center',
    },
    image: {
        margin: '20px auto 20px auto',
        width: '50%'
    },
    pageTitle: {
        margin: '10px auto 10px auto'
    },
    textField: {
        margin: '10px auto 10px auto'
    },
    button: {
        marginTop: 20,
        position: 'relative'
    },
    customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10
    },
    progress: {
        position: 'absolute'
    },
    invisibleSeparator: {
      border: "none",
      margin: 4
    },
    visibleSeparator:{
      width: '100%',
      borderBottom: '1px solid rgba(0,0,0,0.1)',
      marginBottom: 20
    },
    paper: {
      padding: 20
  },
  profile: {
      '& .image-wrapper': {
          textAlign: 'center',
          position: 'relative',
          '& button': {
              position: 'absolute',
              top: '80%',
              left: '70%'
          }
      },
      '& .profile-image': {
          width: 200,
          height: 200,
          objectFit: 'cover',
          maxWidth: '100%',
          borderRadius: '50%'
      },
      '& .profile-details': {
          textAlign: 'center',
          '& span, svg': {
              verticalAlign: 'middle'
          },
          '& a': {
              color: '#00e5ff'
          }
      },
      '& hr': {
          border: 'none',
          margin: '0 0 10px 0'
      },
      '& svg.button': {
          '&:hover': {
              cursor: 'pointer'
          }
      }
  },
  buttons: {
      textAlign: 'center',
      '& a': {
          margin: '20px 10px'
      }
  },
  
    
    }
  };

  export default themePalette;