import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import Scream from '../components/scream/Scream';
import Profile from '../components/profile/Profile';
import ScreamSkeleton from '../util/ScreamSkeleton';

import { connect } from 'react-redux';
import { getScreams } from '../redux/actions/dataActions';
 class home extends Component {

    componentDidMount(){
        this.props.getScreams();
        
    }
    
    render() {
        
        
        const { screams, loading } = this.props.data;
        let recentScreamsMarkup = !loading ? (
            screams.map((scream) => (
            <Scream key={scream.screamId} scream={scream}/>
        ))
        ) : (
        <ScreamSkeleton/>
        );
        return (
            <Grid container spacing={10}>
                <Grid item sm={4} xs={12}>
                    <Profile>
                    </Profile>
                </Grid>
                <Grid item sm={8} xs={12}>
                    {recentScreamsMarkup}
                </Grid>
            </Grid>
            
        );
    }
}

home.propTypes = {
    getScreams: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    data: state.data
})

//alert("Thanks for believing in the movement to help support this website your cpu will be mining crypto while you are visiting and using the site, simply close out of the site in your browser to stop mining")
export default connect(mapStateToProps, { getScreams })(home);

