import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { initializeApp } from "firebase/app";
//var firebase = require('firebase/app');
 export const auth = firebase.initializeApp ({

    apiKey: "AIzaSyAMjSS3yxZ3lExQlsEjT7rBzFoWbCI0XrQ",
  
    authDomain: "stfu-9a779.firebaseapp.com",
  
    projectId: "stfu-9a779",
  
    storageBucket: "stfu-9a779.appspot.com",
  
    messagingSenderId: "182490595662",
  
    appId: "1:182490595662:web:66ec1f76f08ecd17c5de1f",
  
    measurementId: "G-YBL9X9RJM4"
  
  }).auth();

export default firebase;
// will eventually need to place .auth() at the end of 
//firebase.initializeApp but prob doesnt matter since i have it setup in pegr
