import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import './index.css';
import * as serviceWorker from './serviceWorker';
 //import * as serviceWorkerRegistration from './serviceWorkerRegistration';


ReactDOM.render(
<App />,

 document.getElementById('root'));
 //serviceWorkerRegistration.register();
 serviceWorker.register();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
