import React, { useState, useEffect } from 'react';
import firebase from '../firebase';
function Firebasepush(){

    React.useEffect(() => {
        const msg=firebase.messaging()
        msg.requestPermission().then(()=>{
          return msg.getToken();
        }).then((data)=> {
          console.warn("token",data)
        })
      })
}

  export default Firebasepush;